import { h, ref } from 'vue'
import { RouterLink } from 'vue-router'

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
  },

  setup (props) {
    // source text
    const source = props.source.split(' ')

    // output text
    const tokens = ref([])

    // update function (note how it calls itself)
    const update = () => {
      // small delay before running the inner function
      setTimeout(function () {
        const value = source.shift()
        if (value) {
          tokens.value.push(value)
          update()
        }
      }, 100)
    }

    // return values so they are available to the rest of the component
    return {
      tokens,
      update,
    }
  },

  // start the animation!
  mounted () {
    this.update()
  },

  // the return of this function is what is rendered
  // Vue knows to call render each time a property in setup() is updated
  // so when this the output ref updates, we get ore text
  render () {
    // normal render function
    // return h('span', null, 'hello');

    // debug
    console.log(this)

    // this loops over the tokens in output, and the function returns DOM nodes (h)
    const tags = []
    for (const token of this.tokens) {
      // test for link
      const matches = token.match(/\[(.+?)\]\((.+?)\)/)

      // if we have a link, extract the values and render a router link
      if (matches) {
        const [, text, to] = matches
        tags.push(h(RouterLink, { to }, text), ' ')
      } else if (token.match('#')) {s
        tags.push(h('div', { class : 'break', innerHTML: '<br>', style: {'font-size': '0px'}}), ' ')
      } else if (token.match('/')) {
        tags.push(h('div', { class : 'break', innerHTML: '<br>' }), ' ')
      }

      // if no link, just render a span
      else {
        tags.push(h('span', null, token), ' ')
      }
    }

    // console.log(arguments);

    // return the final content
    // return tags;
    return h('code', { class: 'typer' }, tags)
  },
}
