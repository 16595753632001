<template>
<div class="home">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <body>
    <div id="app"></div>
    <canvas id="bg"></canvas>
    <main>
        <header>
            <h1>.</h1>
          </header>
          <section>
            <div v-if="!isMobile()">
              <desktop>
              <div id = "desktop">
               <!-- Typer source = "my name is leare # i'm a software engineer # and love making things / zoom, spin and have fun # if you are feeling brave, # click [here](/techno_logy)"/> -->
              <router-link to="/nology">hello world</router-link>
              <p id = "typing">
                </p>
                </div>
              </desktop>
            </div>
            <div v-else>
              <mobile>
                <div id = "mobile">
                <router-link to="/nology">hello world</router-link>
                <p id = "typing"></p>
                </div>
              </mobile>
            </div>
          </section>
    </main>
  </body>
  </div>
</template>

<style scoped>
    canvas {
    position: fixed;
    top: 0;
    left: 0;
    }

    main {
    position: absolute;
    color: white;
    padding: 2.5rem;  
    font-family : 'Courier New', monospace;
    } 

    img {
      width: 2rem;
    }

    header {
    font-size: 3rem;
    color: white;
    }

    .typeWriter {
      padding: 2rem;
      background: black; 
      font-size: 1.2em;
      margin-top: -5rem;
      padding-left: 0.5rem;
    }

    #desktop {
    padding: 2rem;
    background: black; 
    font-size: 1.2em;
    margin-top: -5rem;
    padding-left: 0.5rem;
    }

    #mobile {
    padding: 2rem;
    background: black; 
    font-size: 0.85em;
    margin-top: -5rem;
    padding-left: 0.5rem;
    }


    a {
    color: white;
    }

    #bg:hover {
      cursor: grab;
    }
</style>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { event } from 'vue-gtag'
import Typer from '../components/Typer';

export default {
  components: {
    Typer,
  },
  data(){
    return {width: window.innerWidth, height: window.innerHeight };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("beforeunload", this.clearSessionStorage);
    if (!sessionStorage.getItem('initialized')) {
      sessionStorage.removeItem('typewriterEffectDone');
      sessionStorage.setItem('initialized', 'true');
    }
  },
  mounted () {

  // Check session storage and run typewriter effect if not done
  if (!sessionStorage.getItem('typewriterEffectDone')) {
      this.typeWriter();
      sessionStorage.setItem('typewriterEffectDone', 'true');
    }

    const scene = new THREE.Scene();

    const camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('#bg'),
    })

    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    camera.position.setZ(10);
    camera.position.setY(30);
    camera.position.setX(-3);

    renderer.render( scene, camera );

    const geometry = new THREE.TorusKnotGeometry(10, 3, 500, 40);
    const material = new THREE.MeshBasicMaterial( { color: 0xFFFFFF, wireframe: true });
    // NOTE : Alternative colour | red : 0xFF6347
    const torusKnot = new THREE.Mesh( geometry, material );

    scene.add(torusKnot);

    const controls = new OrbitControls(camera, renderer.domElement);

    function animate() {
    requestAnimationFrame( animate );

    torusKnot.rotation.x += 0.001;
    torusKnot.rotation.y += 0.005;
    torusKnot.rotation.z += 0.01;
    
    controls.update();
    // TODO : add event listener logic -> for stopping animate in case of event
    renderer.render( scene, camera );
    }
    
    animate();

  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
      onResize(e) {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      },
      isMobile(){
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) { 
          return true
        } else {
          return false
        }
      },
      typeWriter() {
      let i = 0;
      const text = "my name leare # i'm a software engineer # who loves making things # # zoom, spin and have fun # if you are feeling brave # click link above ^";
      const speed = 20;

      const typeWriterEffect = () => {
        if (i < text.length) {
          if (text.charAt(i) === '#') {
            document.getElementById('typing').insertAdjacentHTML('beforeend', '<br>');
          } else {
            document.getElementById('typing').innerHTML += text.charAt(i);
          }
          i++;
          setTimeout(typeWriterEffect, speed);
        }
      };

      typeWriterEffect();
    },
    clearSessionStorage() {
      sessionStorage.removeItem('typewriterEffectDone');
    },
  }
};
</script>
