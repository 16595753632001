import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'

const app = createApp(App);

app.use(router);

app.use(VueGtag, {
    appName: 'leare-io',
    pagetrackerScreenviewEnabled: true,
    config: {
        id: 'G-X7YX66R1JQ', 
    },  
}, router);

app.mount('#app');
