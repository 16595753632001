import { createWebHistory, createRouter } from "vue-router";
import Home from '../views/Home.vue';
import Techno_logy from '../views/Techno_logy.vue';

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/nology",
        name: "nology",
        component: Techno_logy,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router