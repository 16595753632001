<template>
  <div class="nology">
        <canvas id="bg" style="position:fixed; vue sertop:0; left:0; z-index: 0"></canvas>
        <audio preload="true" autoplay="true" start>
        <source src="../assets/man-over-machine.mp3#t=00:02:12" type="audio/mp3">
        Your browser does not support the audio element.
        </audio>
        <p>sound on + volume up</p>
  </div>
</template>

<style scoped>
    canvas {
    position: fixed;
    top: 0;
    left: 0;
    }

    p {
    position: absolute;
    color: white;
    padding: 2.5rem;  
    font-family : 'Courier New', monospace;
    } 

    #bg:hover {
      cursor: help;
    }
</style>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default {
  mounted () {

    const scene = new THREE.Scene();

    const camera = new THREE.PerspectiveCamera(100, window.innerWidth / window.innerHeight, 0.01, 1000);

    const renderer = new THREE.WebGLRenderer({
      canvas: document.querySelector('#bg'),
    })

    console.log("debug")

    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    camera.position.setZ(30);
    camera.position.setY(65);
    camera.position.setX(-3);

    renderer.render( scene, camera );

    const geometry = new THREE.TorusKnotGeometry(40, 50, 400, 40);
    const material = new THREE.MeshBasicMaterial( { color: 0xFFFFFF, wireframe: true });
// NOTE : Alternative material colour | red : 0xFF6347
    const torusKnot = new THREE.Mesh( geometry, material );

    scene.add(torusKnot);

    const controls = new OrbitControls(camera, renderer.domElement);

    function animate() {
      requestAnimationFrame( animate );

      torusKnot.rotation.x += 0.01;
      torusKnot.rotation.y += 0.005;
      torusKnot.rotation.z += 0.03;

      controls.update();
      // TODO : add event listener logic -> for stopping animate in case of event
      renderer.render( scene, camera );

      /*
      const audio = new Audio('/assets/man-over-machine.mp3')
      audio.addEventListener('canplaythrough', function () {
        audio.play()
      })
      */
    }

    animate();
  }
}
</script>
